import request from '@/utils/request'

export function roomList() {
    return request({
        url: 'house/list',
        method: 'get',
    })
}

export function floorList() {
    return request({
        url: 'house/floor',
        method: 'get',
    })
}

export function roomDetail(id: string) {
    return request({
        url: 'house/detail',
        method: 'post',
        data: { id },
    })
}

export function roomStay(data: any) {
    return request({
        url: 'house/stay',
        method: 'post',
        data,
    })
}

export function roomRent(data: any) {
    return request({
        url: 'house/rent',
        method: 'post',
        data,
    })
}

export function roomCost(data: any) {
    return request({
        url: 'house/cost',
        method: 'post',
        data,
    })
}

export function roomExpire(id: number) {
    return request({
        url: 'house/expire',
        method: 'post',
        data: { id },
    })
}

export function roomRentUpdate(data: any) {
    return request({
        url: 'house/rent-update',
        method: 'post',
        data,
    })
}

export function roomRemark(data: any) {
    return request({
        url: 'house/remark',
        method: 'post',
        data,
    })
}

export function roomPerson(id: string) {
    return request({
        url: 'house/person',
        method: 'post',
        data: { id },
    })
}

export function personSave(data: object) {
    return request({
        url: 'house/person-save',
        method: 'post',
        data,
    })
}

export function personDelete(id: string) {
    return request({
        url: 'house/person-delete',
        method: 'post',
        data: { id },
    })
}

export function houseStatistics(data: any) {
    return request({
        url: 'house/statistics',
        method: 'post',
        data,
    })
}

export function houseLog(id: string) {
    return request({
        url: 'house/log',
        method: 'post',
        data: { id },
    })
}
